export const SIGNUP_REQUEST                       = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS                       = 'SIGNUP_SUCCESS'
export const SIGNUP_FAULRE                        = 'SIGNUP_FAULRE'
export const LOGIN_REQUEST                        = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS                        = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE                        = 'LOGIN_FAILURE'
export const VERIFY_ACCOUNT_REQUEST               = 'VERIFY_ACCOUNT_REQUEST'
export const VERIFY_ACCOUNT_SUCCESS               = 'VERIFY_ACCOUNT_SUCCESS'
export const VERIFY_ACCOUNT_FAILURE               = 'VERIFY_ACCOUNT_FAILURE'
export const CLIENT_TOKEN_REQUEST                 = 'CLIENT_TOKEN_REQUEST'
export const CLIENT_TOKEN_SUCCESS                 = 'CLIENT_TOKEN_SUCCESS'
export const CLINET_TOKEN_FAILURE                 = 'CLINET_TOKEN_FAILURE'
export const ADD_COMPANY_DETAILS_REQUEST          = 'ADD_COMPANY_DETAILS_REQUEST'
export const ADD_COMPANY_DETAILS_SUCCESS          = 'ADD_COMPANY_DETAILS_SUCCESS'
export const ADD_COMPANY_DETAILS_FAILURE          = 'ADD_COMPANY_DETAILS_FAILURE'
export const ADD_PROFESSIONAL_DETAILS_REQUEST     = 'ADD_PROFESSIONAL_DETAILS_REQUEST'
export const ADD_PROFESSIONAL_DETAILS_SUCCESS     = 'ADD_PROFESSIONAL_DETAILS_SUCCESS'
export const ADD_PROFESSIONAL_DETAILS_FAILURE     = 'ADD_PROFESSIONAL_DETAILS_FAILURE'
export const ADD_PROFESSIONAL_PHONE_REQUEST       = 'ADD_PROFESSIONAL_PHONE_REQUEST'
export const ADD_PROFESSIONAL_PHONE_SUCCESS       = 'ADD_PROFESSIONAL_PHONE_SUCCESS'
export const ADD_PROFESSIONAL_PHONE_FAILURE       = 'ADD_PROFESSIONAL_PHONE_FAILURE'
export const VERIFY_PROFESSIONAL_PHONE_REQUEST    = 'VERIFY_PROFESSIONAL_PHONE_REQUEST'
export const VERIFY_PROFESSIONAL_PHONE_SUCCESS    = 'VERIFY_PROFESSIONAL_PHONE_SUCCESS'
export const VERIFY_PROFESSIONAL_PHONE_FAILURE    = 'VERIFY_PROFESSIONAL_PHONE_FAILURE'
export const ACCOUNT_LOGOUT_REQUEST               = 'ACCOUNT_LOGOUT_REQUEST'
export const FETCH_COMPANY_DETAILS_REQUEST        = 'FETCH_COMPANY_DETAILS_REQUEST'
export const FETCH_COMPANY_DETAILS_SUCCESS        = 'FETCH_COMPANY_DETAILS_SUCCESS'
export const FETCH_COMPANY_DETAILS_FAILURE        = 'FETCH_COMPANY_DETAILS_SUCCESS'
export const FETCH_PROFESSIONAL_DETAILS_REQUEST   = 'FETCH_PROFESSIONAL_DETAILS_REQUEST'
export const FETCH_PROFESSIONAL_DETAILS_SUCCESS   = 'FETCH_PROFESSIONAL_DETAILS_SUCCESS'
export const FETCH_PROFESSIONAL_DETAILS_FAILURE   = 'FETCH_PROFESSIONAL_DETAILS_FAILURE'
export const FETCH_POST_CODES_ADDRESSES_REQUEST   = 'FETCH_POST_CODES_ADDRESSES_REQUEST'
export const FETCH_POST_CODES_ADDRESSES_SUCCESS   = 'FETCH_POST_CODES_ADDRESSES_SUCCESS'
export const FETCH_POST_CODES_ADDRESSES_FAILURE   = 'FETCH_POST_CODES_ADDRESSES_FAILURE'
export const FETCH_TIMESHEETS_REQUEST             = 'FETCH_TIMESHEETS_REQUEST'
export const FETCH_TIMESHEETS_SUCCESS             = 'FETCH_TIMESHEETS_SUCCESS'
export const FETCH_TIMESHEETS_FAILURE             = 'FETCH_TIMESHEETS_FAILURE'
export const ADD_TIMESHEET_DAILY_SCHEDULE         = 'ADD_TIMESHEET_DAILY_SCHEDULE'
export const ADD_TIMESHEET_REQUEST                = 'ADD_TIMESHEET_REQUEST'
export const ADD_TIMESHEET_SUCCESS                = 'ADD_TIMESHEET_SUCCESS'
export const ADD_TIMESHEET_FAILURE                = 'ADD_TIMESHEET_FAILURE'
export const RESET_SCHEDULE_FORM                  = 'RESET_SCHEDULE_FORM'
export const REMOVE_TIMESHEET_REQUEST             = 'REMOVE_TIMESHEET_REQUEST'
export const REMOVE_TIMESHEET_SUCCESS             = 'REMOVE_TIMESHEET_SUCCESS'
export const REMOVE_TIMESHEET_FAILURE             = 'REMOVE_TIMESHEET_FAILURE'
export const TWO_FACTOR_LOGIN                     = 'TWO_FACTOR_LOGIN'
export const TWO_FACTOR_AUTHENTICATION_REQUEST    = 'TWO_FACTOR_AUTHENTICATION_REQUEST'
export const TWO_FACTOR_AUTHENTICATION_SUCCESS    = 'TWO_FACTOR_AUTHENTICATION_SUCCESS'
export const TWO_FACTOR_AUTHENTICATION_FAILURE    = 'TWO_FACTOR_AUTHENTICATION_FAILURE'
export const PROFESSIONAL_PROFILE_UPDATE_REQUEST  = 'PROFESSIONAL_PROFILE_UPDATE_REQUEST'
export const PROFESSIONAL_PROFILE_UPDATE_SUCCESS  = 'PROFESSIONAL_PROFILE_UPDATE_SUCCESS'
export const PROFESSIONAL_PROFILE_UPDATE_FAILURE  = 'PROFESSIONAL_PROFILE_UPDATE_FAILURE'
export const PROFESSIONAL_SECURITY_UPDATE_REQUEST = 'PROFESSIONAL_SECURITY_UPDATE_REQUEST'
export const PROFESSIONAL_SECURITY_UPDATE_SUCCESS = 'PROFESSIONAL_SECURITY_UPDATE_SUCCESS'
export const PROFESSIONAL_SECURITY_UPDATE_FAILURE = 'PROFESSIONAL_SECURITY_UPDATE_FAILURE'
export const SHIFT_STATUS_UPDATE_REQUEST          = 'SHIFT_STATUS_UPDATE_REQUEST'
export const SHIFT_STATUS_UPDATE_SUCCESS          = 'SHIFT_STATUS_UPDATE_SUCCESS'
export const SHIFT_STATUS_UPDATE_FAILURE          = 'SHIFT_STATUS_UPDATE_SUCCESS'
export const UPDATE_SHIFT_REQUEST                 = 'UPDATE_SHIFT_REQUEST'
export const UPDATE_SHIFT_SUCCESS                 = 'UPDATE_SHIFT_SUCSESS'
export const UPDATE_SHIFT_FAILURE                 = 'UPDATE_SHIFT_FAILURE'
export const PHONE_NUMBER_CHANGE_REQUEST          = 'PHONE_NUMBER_CHANGE_REQUEST'
export const POST_CODE_CHANGE_REQUEST             = 'POST_CODE_CHANGE_REQUEST'
export const COMPANY_PASSWORD_CHANGE_REQUEST      = 'COMPANY_PASSWORD_CHANGE_REQUEST'
export const COMPANY_PASSWORD_CHANGE_SUCCESS      = 'COMPANY_PASSWORD_CHANGE_SUCCESS'
export const COMPANY_PASSWORD_CHANGE_FAILURE      = 'COMPANY_PASSWORD_CHANGE_FAILURE'
export const UPDATE_COMPANY_REQUEST               = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS               = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE               = 'UPDATE_COMPANY_FAILURE'
export const FIND_PROFESSIONALS_REQUEST           = 'FIND_PROFESSIONALS_REQUEST'
export const FIND_PROFESSIONALS_SUCCESS           = 'FIND_PROFESSIONALS_SUCCESS'
export const FIND_PROFESSIONALS_FAILURE           = 'FIND_PROFESSIONALS_FAILURE'
export const NO_PROFESSIONALS_FOUND               = 'NO_PROFESSIONALS_FOUND'
export const ENLIST_PROFESSIONAL                  = 'ENLIST_PROFESSIONAL'
export const MAKE_PAYMENT_REQUEST                 = 'MAKE_PAYMENT_REQUEST'
export const MAKE_PAYMENT_SUCCESS                 = 'MAKE_PAYMENT_SUCCESS'
export const MAKE_PAYMENT_FAILURE                 = 'MAKE_PAYMENT_FAILURE'
export const FETCH_PAYPAL_TOKEN_REQUEST           = 'FETCH_PAYPAL_TOKEN_REQUEST'
export const FETCH_PAYPAL_TOKEN_SUCCESS           = 'FETCH_PAYPAL_TOKEN_SUCCESS'
export const FETCH_PAYPAL_TOKEN_FAILURE           = 'FETCH_PAYPAL_TOKEN_FAILURE'
export const ADD_BANK_DETAILS_REQUEST             = 'ADD_BANK_DETAILS_REQUEST'
export const ADD_BANK_DETAILS_SUCCESS             = 'ADD_BANK_DETAILS_SUCCESS'
export const ADD_BANK_DETAILS_FAILURE             = 'ADD_BANK_DETAILS_FAILURE'
export const UPDATE_BANK_DETAILS_REQUEST          = 'UPDATE_BANK_DETAILS_REQUEST'
export const UPDATE_BANK_DETAILS_SUCCESS          = 'UPDATE_BANK_DETAILS_SUCCESS'
export const UPDATE_BANK_DETAILS_FAILURE          = 'UPDATE_BANK_DETAILS_FAILURE'
export const OFFER_REQUEST_INIT                   = 'OFFER_REQUEST_INIT'
export const OFFER_REQUEST_SUCCESS                = 'OFFER_REQUEST_SUCCESS'
export const OFFER_REQUEST_FAILURE                = 'OFFER_REQUEST_FAILURE'
export const OFFER_UPDATE_REQUEST                 = 'OFFER_UPDATE_REQUEST'
export const OFFER_UPDATE_SUCCESS                 = 'OFFER_UPDATE_SUCCESS'
export const OFFER_UPDATE_FAILURE                 = 'OFFER_UPDATE_FAILURE'
export const COMPANY_OFFER_UPDATE_REQUEST         = 'COMPANY_OFFER_UPDATE_REQUEST'
export const COMPANY_OFFER_UPDATE_SUCCESS         = 'COMPANY_OFFER_UPDATE_SUCCESS'
export const COMPANY_OFFER_UPDATE_FAILURE         = 'COMPANY_OFFER_UPDATE_FAILURE'
export const CLEAR_ACCOUNT_ERRORS                 = 'CLEAR_ACCOUNT_ERRORS'
export const MESSAGE_SENDING_REQUEST              = 'MESSAGE_SENDING_REQUEST'
export const MESSAGE_SENDING_SUCCESS              = 'MESSAGE_SENDING_SUCCESS'
export const MESSAGE_SENDING_FAILURE              = 'MESSAGE_SENDING_SUCCESS'
export const FORGOT_PASSWORD_LINK_REQUEST         = 'FORGOT_PASSWORD_LINK_REQUEST'
export const FORGOT_PASSWORD_LINK_SUCCESS         = 'FORGOT_PASSWORD_LINK_SUCCESS'
export const FORGOT_PASSWORD_LINK_FAILURE         = 'FORGOT_PASSWORD_LINK_FAILURE'
export const PASSWORD_RESET_REQUEST               = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS               = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE               = 'PASSWORD_RESET_FAILURE'
export const PROFESSIONAL_MESSAGE_REQUEST         = 'PROFESSIONAL_MESSAGE_REQUEST'
export const PROFESSIONAL_MESSAGE_SUCCESS         = 'PROFESSIONAL_MESSAGE_SUCCESS'
export const PROFESSIONAL_MESSAGE_FAILURE         = 'PROFESSIONAL_MESSAGE_FAILURE'
export const PROCESS_START                        = 'PROCESS_START'
export const PROCESS_END                          = 'PROCESS_END'
export const COMPANY_MESSAGE_REQUEST              = 'COMPANY_MESSAGE_REQUEST'
export const COMPANY_MESSAGE_SUCCESS              = 'COMPANY_MESSAGE_SUCCESS'
export const COMPANY_MESSAGE_FAILURE              = 'COMPANY_MESSAGE_FAILURE'
export const ADD_LOCATION_REQUEST                 = 'ADD_LOCATION_REQUEST'
export const ADD_LOCATION_SUCCESS                 = 'ADD_LOCATION_SUCCESS'
export const ADD_LOCATION_FAILURE                 = 'ADD_LOCATION_FAILURE'
export const UPDATE_EMAIL_REQUEST                 = 'UPDATE_EMAIL_REQUEST'
export const UPDATE_EMAIL_SUCCESS                 = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILURE                 = 'UPDATE_EMAIL_FAILURE'
export const UPDATE_PHONE_REQUEST                 = 'UPDATE_PHONE_REQUEST'
export const UPDATE_PHONE_SUCCESS                 = 'UPDATE_PHONE_SUCCESS'
export const UPDATE_PHONE_FAILURE                 = 'UPDATE_PHONE_FAILURE'
export const UPDATE_PROFESSIONAL_EMAIL_REQUEST    = 'UPDATE_PROFESSIONAL_EMAIL_REQUEST'
export const UPDATE_PROFESSIONAL_EMAIL_SUCCESS    = 'UPDATE_PROFESSIONAL_EMAIL_SUCCESS'
export const UPDATE_PROFESSIONAL_EMAIL_FAILURE    = 'UPDATE_PROFESSIONAL_EMAIL_FAILURE'
export const ADD_PROFESSIONALS_LIST_BY_DISTANCE   = 'ADD_PROFESSIONALS_LIST_BY_DISTANCE'
export const RESET_PROFESSIONALS_LIST             = 'RESET_PROFESSIONALS_LIST'